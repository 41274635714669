<template>
  <div>
    <div>
      <header class="header">
        <div>
          <nav class="title">
            <router-link to="/">
              <img src="../../assets/logo-white.svg" alt/>
            </router-link>
            <ul class="nav">
              <li class="nav-li">
                <router-link to="product" class="nav-item">产品</router-link>
              </li>
              <li class="nav-li">
                <router-link to="contact" class="nav-item">联系我们</router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="section">
          <h1 class="heading">杭州文洁网络有限公司</h1>
          <div>Hangzhou WenJie Network Co., Ltd.</div>
        </div>
      </header>
      <div class="content">
        <div>
          <div class="paragraph">
            <div class="paragraph-p">
              <h1>短信营销</h1>
              <p class="paragraph-text">
                场景通知：催付、付款、发货、派件、签收等场景客户关怀。短信群发营销，快速直达客户，为您提供客户营销解决方案
              </p>
            </div>
          </div>
          <div class="paragraph">
            <div class="paragraph-p">
              <h1>微信核销</h1>
              <p class="paragraph-text">
                便捷高效的核销方案，支持虚拟商品、电子卡券、优惠券、优惠码、门店自提等类型商品。无需给予员工管理员权限、无需下载APP、只需打开微信，一键扫码核销
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="primary-background">
        <div class="container">
          <div>
            <h1 class="container-h1">没有需要的产品，想要定制？</h1>
          </div>
          <button class="btn container-btn" onclick="location.href='/#/contact'">联系我们</button>
        </div>
      </div>
    </div>
    <footerDiv></footerDiv>
  </div>
</template>

<script>
  import footerDiv from "./footer";

  export default {
    name: "index",
    components: {
      footerDiv
    },
    methods: {
      fixed() {
        let rr = setInterval(() => {
          document.documentElement.scrollTop -= 80;
          if (document.documentElement.scrollTop === 0) {
            clearInterval(rr);
          }
        }, 10);
      }
    },
    created() {
    }
  };
</script>

<style lang="scss" scoped>
  * {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  .fixed {
    width: 36px;
    height: 36px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    background-color: white;
    color: red;
    text-align: center;
    line-height: 36px;
  }

  .header {
    width: 100%;
    background-color: #507ce8;
    color: white;
  }

  .section {
    padding: 6rem 1.5rem 3rem;
  }

  .section > div {
    font-size: 20px;
    margin-bottom: 120px;
  }

  .heading {
    font-size: 36px;
  }

  .btn {
    width: 280px;
    height: 50px;
    border-radius: 100px;
    border: none;
    font-size: 16px;
    color: #507ce8;
    box-sizing: border-box;
    padding: 0 30px 0 51px;
    background-image: url("../../assets/logo.png");
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 39px;
    background-color: white;
    outline: none;
    cursor: pointer;
  }

  .content {
    max-width: 1280px;
    margin: 0 auto;
  }

  .content-title {
    padding: 3rem 1.5rem 0;
  }

  .paragraph {
    display: flex;
    align-items: center;
    margin: 4rem;
  }

  .paragraph-p {
    margin: 60px;
  }

  .paragraph-p > h1 {
    text-align: left;
    font-size: 28px;
    color: #546496;
    font-weight: 300;
  }

  .paragraph-p > p {
    text-align: left;
    font-size: 17px;
    color: #6d7d93;
  }

  .column {
    background-color: white;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 2rem;
  }

  .image {
    max-width: 100%;
  }

  .columns {
    box-sizing: border-box;
    padding: 24px 32px;
    border-radius: 8px;
  }

  .columns-icon {
    width: 120px;
    margin-bottom: 2rem;
  }

  .shortcut-key {
    padding: 2px 10px;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border-radius: 2px;
    text-align: center;
    margin: 0 6px;
    color: black;
  }

  .columns-from {
    max-width: 100%;
    margin-top: 32px;
  }

  .columns-ul {
    display: inline-block;
  }

  .columns-ul > li {
    text-align: left;
  }

  .last {
    margin: 4rem 1rem;
  }

  .last-title {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    font-size: 28px;
    line-height: 42px;
    color: #546496;
    padding-top: 2rem;
  }

  .paragraph-list {
    display: flex;
    justify-content: space-between;
  }

  .paragraph-l {
    width: 40%;
    margin: 0 60px;
  }

  .text {
    font-size: 17px;
    margin-top: 1rem;
    height: 100px;
    color: #6d7d93;
  }

  .primary-background {
    background-color: #507ce8;
    padding: 38px 20px;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-btn {
    margin: 2rem;
  }

  .container-h1 {
    font-size: 28px;
    color: white;
  }

  .container-p {
    font-size: 17px;
    color: white;
  }

  .title {
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  }

  .nav-li {
    display: inline-block;
    margin: 0 18px;
  }

  .nav-item {
    font-size: 15px;
    color: #c9d6f5;
    list-style: none;
  }

  @media screen and (max-width: 798px) {
    .title {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px 28px;
    }
    .nav {
      margin-top: 10px;
    }
    .nav-li {
      margin: 0 10px;
    }
    @media screen and (max-width: 798px) {
      .paragraph {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2rem 1rem 4rem;
      }
      .paragraph-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .content {
        margin: 0 auto;
        width: 100%;
      }
      .paragraph-p {
        margin: 10px 30px;
      }
      .paragraph-l {
        width: auto;
        margin: 20px 30px;
      }
      .container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
</style>
